import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import AllRecipes from './allRecipes/AllRecipes.jsx';
import RecipesInMachines from './recipesInMachines/RecipesInMachines.jsx';

const Recipe = () => {
	return (
		<div className="card">
			<TabView>
				<TabPanel header="Recipes in machines">
					<RecipesInMachines />
				</TabPanel>
				<TabPanel header="All recipes">
					<AllRecipes />
				</TabPanel>
			</TabView>
		</div>
	);
};

export default Recipe;
