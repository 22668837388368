import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import moment from 'moment';

import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';

const ActiveCodes = ({
	setCode,
	codes,
	setCodes,
	revokedCodes,
	setRevokedCodes,
	filters,
	getSeverity,
	descriptionBodyTemplate,
	toast,
	onGlobalFilterChange,
	globalFilterValue,
	setPromotion,
	setSubmittedPromotion,
	setSubmittedCode,
	setPromotionDialog,
	setCodeDialog,
	emptyPromotion,
	emptyCode,
	setCodeDialogTitle,
}) => {
	const api = new ApiService();
	const { authTokens } = useAuth();
	const headers = {
		'X-Firebase-Auth-Client': authTokens.token,
	};

	const openNewPromotion = () => {
		setPromotion(emptyPromotion);
		setSubmittedPromotion(false);
		setPromotionDialog(true);
	};

	const openNewCode = () => {
		setCode(emptyCode);
		setSubmittedCode(false);
		setCodeDialog(true);
		setCodeDialogTitle('Create Code');
	};

	const editCode = (rowData) => {
		// Need to convert date because it's stored in database a format that the Date constructor doesn't understand (DD-MM-YYYY HH:MM) or (DD.MM.YYYY, HH:MM)
		const formattedStartTime = moment(rowData.startTime, 'DD-MM-YYYY HH:mm').toDate();
		const formattedEndTime = moment(rowData.endTime, 'DD-MM-YYYY HH:mm').toDate();

		const objForEditCode = {
			id: rowData.id,
			name: rowData.name,
			description: rowData.description,
			maxClaims: rowData.maxClaims,
			maxClaimsPerUser: rowData.maxClaimsByUser,
			startTime: formattedStartTime,
			endTime: formattedEndTime,
			promotionId: rowData.promotion.id,
		};

		setCode(objForEditCode);
		setPromotion(rowData.promotion);
		setSubmittedCode(true);
		setCodeDialog(true);
		setCodeDialogTitle('Edit Code');
	};

	const revokeCode = async (code, index) => {
		try {
			await api.revokeCode(code.id, headers);

			// remove code from active codes
			let _codes = [...codes];
			_codes.splice(index, 1);

			setCodes(_codes);

			// add code to revoked codes
			let _revokedCodes = [...revokedCodes];
			_revokedCodes.push(code);

			setRevokedCodes(_revokedCodes);

			toast.current.show({
				severity: 'success',
				summary: 'Success',
				detail: 'Code successfully revoked',
				life: 3000,
			});
		} catch (error) {
			console.log(error);

			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'Something went wrong while revoking the code',
				life: 3000,
			});
		}
	};

	const header = (
		<div className="flex align-items-center justify-content-between">
			<span className="p-input-icon-left">
				<i className="pi pi-search" />
				<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
			</span>

			<div className="flex align-items-center justify-content-end">
				<Button label="Create Code" icon="pi pi-plus" className="mr-2" onClick={openNewCode} />
				<Button label="Add Promotion" icon="pi pi-plus" severity="secondary" outlined onClick={openNewPromotion} />
			</div>
		</div>
	);

	return (
		<>
			<DataTable
				value={codes}
				paginator
				rows={10}
				rowsPerPageOptions={[10, 25, 50]}
				removableSort
				filters={filters}
				emptyMessage="No codes found"
				header={header}
				tableStyle={{ minWidth: '60rem' }}
				globalFilterFields={[
					'name',
					'description',
					'maxClaims',
					'maxClaimsByUser',
					'startTime',
					'endTime',
					'totalClaimed',
					'promotion?.type',
				]}
				size="small"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
			>
				<Column
					field="name"
					header="Codes"
					sortable
					style={{ minWidth: '3rem' }}
					body={descriptionBodyTemplate}
				></Column>
				<Column field="maxClaims" header="Max" sortable style={{ minWidth: '3rem' }}></Column>
				<Column field="maxClaimsByUser" header="Max per user" sortable style={{ minWidth: '5rem' }}></Column>
				<Column field="startTime" header="Start date" sortable style={{ minWidth: '5rem' }}></Column>
				<Column field="endTime" header="End date" sortable style={{ minWidth: '5rem' }}></Column>
				<Column field="totalClaimed" header="Total claimed" sortable style={{ minWidth: '5rem' }}></Column>
				<Column
					field="promotion.type"
					header="Promotion"
					sortable
					style={{ minWidth: '5rem' }}
					body={(rowData) => <Tag severity={getSeverity(rowData.promotion?.type)}>{rowData.promotion?.type}</Tag>}
				></Column>
				<Column
					field="id"
					body={(rowData) => (
						<div className="flex align-items-center justify-content-center">
							<Button
								rounded
								text
								size="small"
								icon="pi pi-pencil"
								className="p-button-warning"
								style={{ marginRight: '1rem', padding: '0.4rem', height: '1.4rem', width: '1.4rem' }}
								onClick={() => editCode(rowData)}
							/>
							<Button
								rounded
								text
								size="small"
								icon="pi pi-trash"
								className="p-button-danger"
								style={{ padding: '0.4rem', height: '1.4rem', width: '1.4rem' }}
								onClick={() => revokeCode(rowData)}
							/>
						</div>
					)}
					style={{ minWidth: '5rem' }}
				></Column>
			</DataTable>
		</>
	);
};

export default ActiveCodes;
