import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../context/auth';
import ApiService from '../../services/ApiService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FilterMatchMode, FilterService } from 'primereact/api';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Dropdown } from 'primereact/dropdown';

import './RecipesInMachines.css';

const api = new ApiService();

FilterService.register('custom_badgeIconUrl', (value, filter) => {
	if (filter === null) {
		return true;
	}
	if (filter === true) {
		return value !== '';
	}
	if (filter === false) {
		return value === '';
	}
	return true;
});

FilterService.register('custom_smoothieParts', (value, filter) => {
	if (!filter || filter.trim().length === '') {
		return true;
	}

	if (!value) {
		return false;
	}

	return value.some((ingredient) => ingredient.flavor.name.toLowerCase().includes(filter.toLowerCase()));
});

const RecipesInMachines = () => {
	const { authTokens } = useAuth();
	const history = useHistory();

	const [loadingRecipes, setLoadingRecipes] = useState(true);
	const [loadingMachines, setLoadingMachines] = useState(true);

	const [machineRecipes, setMachineRecipes] = useState([]);
	const [machinesForRecipe, setMachinesForRecipe] = useState([]);
	const [categories, setCategories] = useState([]);

	const [expandedRows, setExpandedRows] = useState({});
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		categories: { value: null, matchMode: FilterMatchMode.CONTAINS },
		badgeIconUrl: { value: null, matchMode: FilterMatchMode.CUSTOM },
		smoothieParts: { value: null, matchMode: FilterMatchMode.CUSTOM },
	});
	const [amountOfResults, setAmountOfResults] = useState(0);
	const [totalResults, setTotalResults] = useState(0);

	const headers = useMemo(
		() => ({
			'X-Firebase-Auth-Client': authTokens.token,
		}),
		[authTokens],
	);

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const response = await api.getRecipeCategories(headers);
				setCategories(response.data.map((category) => ({ name: category, value: category }))); // Ensure correct format
			} catch (error) {
				console.error(error);
			}
		};

		fetchCategories();

		return () => {
			setCategories([]);
		};
	}, [headers]);

	useEffect(() => {
		const fetchMachineRecipes = async () => {
			setLoadingRecipes(true);
			try {
				const response = await api.getAllMachineRecipes(headers);
				setMachineRecipes(response.data.recipes);
				setTotalResults(response.data.recipes.length);
				setAmountOfResults(response.data.recipes.length);
			} catch (error) {
				console.error(error);
			} finally {
				setLoadingRecipes(false);
			}
		};

		fetchMachineRecipes();

		return () => {
			setMachineRecipes([]);
		};
	}, [headers]);

	const fetchMachinesForRecipe = async (recipeId) => {
		setLoadingMachines(true);

		try {
			const response = await api.getMachinesForRecipe(recipeId, true, headers);
			const machineIds = response.data;

			const machineDetails = await Promise.all(
				machineIds.map(async (machineId) => {
					const machineResponse = await api.getMachine(machineId, headers);
					return machineResponse.data;
				}),
			);

			setMachinesForRecipe((prev) => ({
				...prev,
				[recipeId]: machineDetails,
			}));
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingMachines(false);
		}
	};

	const onRowToggle = (e) => {
		const newExpandedRows = e.data;
		setExpandedRows(newExpandedRows);

		Object.keys(newExpandedRows).forEach((key) => {
			if (newExpandedRows[key]) {
				const recipeId = key;
				if (recipeId && !machinesForRecipe[recipeId]) {
					fetchMachinesForRecipe(recipeId);
				}
			}
		});
	};

	const machineTemplate = (rowData) => {
		const machines = machinesForRecipe[rowData.id] || [];

		return (
			<DataTable value={machines} loading={loadingMachines} sortField="name" sortOrder={1} removableSort>
				<Column field="name" header="Machine Name" sortable style={{ width: '10rem', fontWeight: 500 }} />
				<Column field="customerName" header="Customer Name" />
			</DataTable>
		);
	};

	const getSeverity = (type) => {
		switch (type) {
			case 'ORIGINAL':
				return 'primary';
			case 'SMOOTHIE':
				return 'info';
			case 'SOUP':
				return 'info';
			case 'FRAPE':
				return 'info';
			case 'MOBILE':
				return 'success';
			case 'MACHINE':
				return 'success';
			case 'TEST':
				return 'warning';
			default:
		}
	};

	const categoriesTemplate = (rowData) => {
		const categories = rowData.categories;

		return (
			<div>
				{categories.map((type) => (
					<Tag
						key={type}
						value={type.toLowerCase()}
						className="recipes-template-categories"
						severity={getSeverity(type)}
					/>
				))}
			</div>
		);
	};

	const categoryItemTemplate = (option) => {
		return <Tag key={option.name} value={option.name.toLowerCase()} severity={getSeverity(option.name)} />;
	};

	const categoryRowFilterTemplate = (options) => {
		return (
			<Dropdown
				value={options.value}
				options={categories}
				onChange={(e) => options.filterApplyCallback(e.value)}
				itemTemplate={categoryItemTemplate}
				placeholder="Select a category"
				optionLabel={(option) => option.name.toLowerCase()}
				optionValue="value"
				className="p-column-filter"
				showClear
			/>
		);
	};

	const actionTemplate = (rowData) => {
		return (
			<Button icon="pi pi-pencil" text rounded size="small" onClick={() => history.push('/recipe/update', rowData)} />
		);
	};

	const recipeTemplate = (rowData) => {
		return (
			<div className="flex align-items-center w-full">
				<div className="recipe-template-container">
					<img src={rowData.image} alt={rowData.name} className="recipe-template-image" />
				</div>

				<div className="recipe-template-content-container">
					<p className="recipe-template-name">{rowData.name}</p>

					<p className="recipe-template-description-container-description">{rowData.description}</p>
				</div>
			</div>
		);
	};

	const labelTemplate = (rowData) => {
		return (
			<div className="recipe-template-label-container">
				{rowData.badgeIconUrl ? <img src={rowData.badgeIconUrl} alt="Label" /> : <span>--</span>}
			</div>
		);
	};

	const labelRowFilterTemplate = (options) => {
		return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
	};

	const ingredientsTemplate = (rowData) => {
		return (
			<div className="flex align-items-center gap-1">
				{rowData.smoothieParts.map((ingredient) => (
					<img
						key={ingredient.id}
						src={ingredient.flavor.icon}
						alt={ingredient.flavor.name}
						className="recipe-ingredient-template-image"
					/>
				))}
			</div>
		);
	};

	const header = (
		<div className="flex align-items-center justify-content-between">
			<Button
				label="Create new Recipe"
				icon="pi pi-plus"
				text
				size="small"
				onClick={() => history.push('/recipe/create')}
			/>

			<div className="action-bar-totals">
				<p>
					Showing {amountOfResults} of {totalResults} items
				</p>
			</div>
		</div>
	);

	const rowClassName = (rowData) => {
		return {
			'highlighted-row': expandedRows[rowData.id] === true,
		};
	};

	const onValueChange = (e) => {
		if (!e.length) {
			return;
		}

		setAmountOfResults(e.length);
	};

	return (
		<div className="mt-5 main-datatable">
			<DataTable
				sortField="name"
				sortOrder={1}
				value={machineRecipes}
				expandedRows={expandedRows}
				onRowToggle={onRowToggle}
				rowExpansionTemplate={machineTemplate}
				header={header}
				dataKey="id"
				removableSort
				emptyMessage="No recipes found"
				filters={filters}
				filterDisplay="row"
				loading={loadingRecipes}
				showGridlines
				rowClassName={rowClassName}
				onValueChange={onValueChange}
				size="small"
				onFilter={(e) => setFilters(e.filters)}
			>
				<Column expander style={{ width: '1rem' }} />
				<Column
					field="name"
					header="Recipe"
					sortable
					body={recipeTemplate}
					style={{ width: '15rem' }}
					filter
					filterPlaceholder="Search by name"
				/>
				<Column
					field="badgeIconUrl"
					header="Label"
					body={labelTemplate}
					style={{ width: '5rem' }}
					filter
					filterElement={labelRowFilterTemplate}
					showFilterMenu={false}
				/>
				<Column
					header="Categories"
					field="categories"
					body={categoriesTemplate}
					filterMenuStyle={{ width: '10rem' }}
					style={{ minWidth: '8rem' }}
					filter
					showFilterMenu={false}
					filterElement={categoryRowFilterTemplate}
				/>
				<Column
					field="smoothieParts"
					header="Ingredients"
					body={ingredientsTemplate}
					showFilterMenu={false}
					style={{ width: '15rem' }}
					filterMenuStyle={{ width: '10rem' }}
					filter
					filterPlaceholder="Search by ingredient"
				/>
				<Column header="Actions" body={actionTemplate} style={{ width: '5rem' }} />
			</DataTable>
		</div>
	);
};

export default RecipesInMachines;
