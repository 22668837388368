import React from 'react';
import { Tag } from 'primereact/tag';

import './ActionBar.css';

const ActionBar = ({
	totalMachines,
	amountOfResults,
	statusFilter,
	typeFilter,
	clearActiveFilters,
	searchFilterValue,
	onhandleSearch,
}) => {
	return (
		<section className="action-bar">
			<div className="action-bar-search-group">
				{(statusFilter || typeFilter) && (
					<div className="action-bar-search-group-active-filters">
						{statusFilter && (
							<Tag className="tag">
								<span className="tag-label">Status: </span>
								<strong>{statusFilter}</strong>
							</Tag>
						)}

						{typeFilter && (
							<Tag className="tag">
								<span className="tag-label">Type: </span>
								<strong>{typeFilter.toLowerCase()}</strong>
							</Tag>
						)}

						<span className="clear-filter-button" aria-label="Cancel" onClick={clearActiveFilters}>
							<i className="pi pi-times"></i>
						</span>
					</div>
				)}

				<div className="action-bar-search">
					<i className="pi pi-search"></i>
					<input type="text" placeholder="Search table..." value={searchFilterValue} onChange={onhandleSearch} />

					{searchFilterValue && (
						<span
							className="clear-filter-button"
							aria-label="Cancel"
							onClick={() => onhandleSearch({ target: { value: '' } })}
						>
							<i className="pi pi-times"></i>
						</span>
					)}
				</div>
			</div>

			<div className="action-bar-totals">
				<p>
					Showing {amountOfResults} of {totalMachines} items
				</p>
			</div>
		</section>
	);
};

export default ActionBar;
