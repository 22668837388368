import { Badge } from 'primereact/badge';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import './MachineDataTable.css';

const MachineDataTable = ({ filteredMachines }) => {
	return (
		<DataTable value={filteredMachines} removableSort tableStyle={{ minWidth: '50rem' }} size="small">
			<Column
				style={{ width: '3rem' }}
				body={(machine) => {
					return (
						<Link
							to={{
								pathname: `/machines/${machine.machineId}`,
								state: machine,
							}}
						>
							<i className="pi pi-cog"></i>
						</Link>
					);
				}}
				bodyClassName="text-center"
			></Column>
			<Column
				field="availableForCustomer"
				header="Status"
				sortable
				style={{ width: '5rem' }}
				body={(rowData) => {
					// return green dot if machine is available for customer
					if (rowData.availableForCustomer) {
						return (
							<div className="table-flex-row">
								<Badge severity="success" />
								<span className="table-status-text">Online</span>
							</div>
						);
					} else {
						return (
							<div className="table-flex-row">
								<Badge severity="danger" />
								<span className="table-status-text">Offline</span>
							</div>
						);
					}
				}}
				bodyClassName="text-center"
			></Column>
			<Column
				field="name"
				header="Name"
				style={{ width: '10rem' }}
				sortable
				body={(rowData) => {
					return (
						<div>
							{rowData.customerName ? (
								<div className="table-flex-column">
									<span className="table-customer-name">{rowData.customerName}</span>
									<span className="table-name">{rowData.name}</span>
								</div>
							) : (
								<span className="table-customer-name">{rowData.name}</span>
							)}
						</div>
					);
				}}
			></Column>
			<Column
				field="plcVersion"
				header="Version"
				sortable
				style={{ width: '10rem' }}
				body={(rowData) => {
					return (
						<div className="table-cell">
							<div className="table-flex-column">
								<span className="table-version">plc: {rowData.plcVersion ? rowData.plcVersion : '--'}</span>
								<span className="table-version">pos: {rowData.posVersion ? rowData.posVersion : '--'}</span>
							</div>
						</div>
					);
				}}
			></Column>
			<Column
				field="pricing.original"
				header="Price"
				sortable
				style={{ width: '5rem' }}
				body={(rowData) => {
					return (
						<span className="table-price">
							{rowData.pricing.original ? rowData.pricing.original + ' ' + rowData.pricing.currency : '--'}
						</span>
					);
				}}
			></Column>
			<Column
				field="type"
				header="Type"
				sortable
				style={{ width: '5rem' }}
				body={(rowData) => {
					const severity = rowData.type === 'SMOOTHIE' ? 'primary' : rowData.type === 'SOUP' ? 'warning' : 'success';
					return (
						<Tag className="table-tag" style={{ textTransform: 'capitalize' }} severity={severity}>
							{rowData.type.toLowerCase()}
						</Tag>
					);
				}}
			></Column>
			<Column
				field="unavailableIngredients"
				header="Unavailable ingr."
				sortable
				style={{ width: '10%' }}
				body={(rowData) => {
					return (
						<div className="table-flex-row">
							<Badge
								className="mr-1"
								value={rowData.unavailableIngredients.length}
								severity={rowData.unavailableIngredients.length > 0 ? 'danger' : 'success'}
							/>

							{rowData.unavailableIngredients.map((ingredient) => {
								return (
									<img
										key={ingredient.id}
										src={ingredient.flavor.icon}
										alt={ingredient.name}
										style={{ width: '1.5rem' }}
										title={ingredient.name}
									/>
								);
							})}
						</div>
					);
				}}
			></Column>
		</DataTable>
	);
};

export default MachineDataTable;
