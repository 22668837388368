import React, { useEffect, useState } from 'react';
import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHeader,
	Icon,
	TableHeaderCell,
	Dropdown,
	Button,
	Checkbox,
	Label,
	TableFooter,
	Message,
} from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
const api = new ApiService();

const MachineIngredient = (props) => {
	const { machineId } = props.location.state ? props.location.state : '';
	const { authTokens } = useAuth();
	const headers = { 'X-Firebase-Auth-Client': authTokens.token };
	const [alertSaveChanges, setAlertSaveChanges] = useState(false);
	const [updateSuccess, setUpdateSuccess] = useState(false);
	const [updateFail, setUpdateFail] = useState(false);

	const [all, setAll] = useState([]);
	const [allIncludingInMachine, setAllIncludingInMachine] = useState([]);
	const [affectedRecipesByChange, setAffectedRecipesByChange] = useState([]);
	const [oldIngredientName, setOldIngredientName] = useState('');
	const [newIngredientName, setNewIngredientName] = useState('');
	const [machineIngredients, setMachineIngredients] = useState([]);
	const [currentMachineRecipes, setCurrentMachineRecipes] = useState(
		props.location.state ? props.location.state.recipes : [],
	);
	const [ingredientsOptions, setIngredientsOptions] = useState([]);
	const [cartridgeOptions, setCartridgeOptions] = useState([
		{ key: '1', value: 0, text: '1', updatedHighlight: false },
		{ key: '2', value: 1, text: '2', updatedHighlight: false },
		{ key: '3', value: 2, text: '3', updatedHighlight: false },
		{ key: '4', value: 3, text: '4', updatedHighlight: false },
		{ key: '5', value: 4, text: '5', updatedHighlight: false },
		{ key: '6', value: 5, text: '6', updatedHighlight: false },
		{ key: '7', value: 6, text: '7', updatedHighlight: false },
		{ key: '8', value: 7, text: '8', updatedHighlight: false },
		{ key: '9', value: 8, text: '9', updatedHighlight: false },
		{ key: '10', value: 9, text: '10', updatedHighlight: false },
	]);
	const [changeIngredientTo, setChangeIngredientTo] = useState([
		{ key: 1, from: {}, to: {}, changed: false },
		{ key: 2, from: {}, to: {}, changed: false },
		{ key: 3, from: {}, to: {}, changed: false },
		{ key: 4, from: {}, to: {}, changed: false },
		{ key: 5, from: {}, to: {}, changed: false },
		{ key: 6, from: {}, to: {}, changed: false },
		{ key: 7, from: {}, to: {}, changed: false },
		{ key: 8, from: {}, to: {}, changed: false },
		{ key: 9, from: {}, to: {}, changed: false },
		{ key: 10, from: {}, to: {}, changed: false },
	]);

	const [machineRecipesId, setMachineRecipesId] = useState([]); // array of recipe ids
	const [allRecipes, setAllRecipes] = useState([]); // array of all recipes

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const getMachineRecipesByMachineId = async () => {
			const response = await api.getMachineRecipes(machineId, headers);
			setMachineRecipesId(response.data);
			console.log('machineRecipesId', response.data);
		};

		const getAllRecipes = async () => {
			const response = await api.getAllMachineRecipes(headers);
			setAllRecipes(response.data.recipes);
			console.log('allRecipes', response.data.recipes);
		};

		getMachineRecipesByMachineId();
		getAllRecipes();
	}, [authTokens.token, machineId]);

	useEffect(() => {
		if (
			!Array.isArray(machineRecipesId) ||
			!Array.isArray(allRecipes) ||
			machineRecipesId.length === 0 ||
			allRecipes.length === 0
		) {
			return;
		}

		// find the recipe object for each recipe id and add it to the machineRecipes array and add the index
		const result = machineRecipesId.map((recipe) => {
			const recipeObj = allRecipes.find((r) => r.id === recipe.recipeId);
			return {
				...recipeObj,
				index: recipe.index,
			};
		});

		// sort the machineRecipes array by index
		result.sort((a, b) => a.index - b.index);

		console.log('result', result);
		setCurrentMachineRecipes(result);
	}, [machineRecipesId, allRecipes]);

	useEffect(() => {
		fetchIngredients();
	}, []);

	useEffect(() => {
		fetchIngredientsState();
	}, [allIncludingInMachine]);

	const fetchIngredientsState = async () => {
		if (allIncludingInMachine.length === 0) return;
		try {
			const arrayOfIngredientsState = await api.getStateIngredientsInMachine(machineId, headers);

			// this array looks like this
			/**
			 * [
			 * {available: true, enabled: true, compartment: 1, ingredientId: '23702cda-ec65-4f1c-9861-f2f5ee099db0'}
			 * {available: true, enabled: true, compartment: 2, ingredientId: '5414ae69-b4bf-4206-8ec5-f17b54a3b4ac'}
			 * ]
			 */

			// look at the ingredientId and find the ingredient in the all array
			// add the available, enabled, and compartment to the ingredient
			const ingredientsInMachine = arrayOfIngredientsState.data.map((state) => {
				const ingredient = allIncludingInMachine.find((ingredient) => ingredient.id === state.ingredientId);
				if (ingredient) {
					ingredient.available = state.available;
					ingredient.enabled = state.enabled;
					ingredient.compartment = state.compartment;
				}
				return ingredient;
			});

			// sort the ingredients by compartment
			ingredientsInMachine.sort((a, b) => {
				return a.compartment - b.compartment;
			});

			setMachineIngredients(ingredientsInMachine);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchIngredients = async () => {
		try {
			const allIngredients = await api.getAllIngredients(headers);
			setAllIncludingInMachine(allIngredients.data);
		} catch (error) {
			console.error(error);
		}
	};

	const IngredientsNotInMachine = async () => {
		try {
			const allIngredients = await api.getAllIngredients(headers);

			//filter ingredients that are already in the machine
			const result = filterAllIngredients(allIngredients);
			setAll(result);

			// create options for dropdown
			const options = result.map((ingredient, index) => {
				return { key: ingredient.id, value: ingredient.id, text: ingredient.name };
			});
			setIngredientsOptions(options);
		} catch (error) {
			console.error(error);
		}
	};

	const filterAllIngredients = (ingredients) => {
		return ingredients.data.filter((data) => !machineIngredients.some((item) => item.id === data.id));
	};

	const updateIngredients = async () => {
		try {
			await api.updateMachineIngredients(machineId, machineIngredients, headers);

			fetchIngredients();
			fetchIngredientsState();

			setAlertSaveChanges(false);

			// reset the changeIngredientTo array
			setChangeIngredientTo(
				changeIngredientTo.map((changeIngredient) => {
					return { ...changeIngredient, from: {}, to: {}, changed: false };
				}),
			);

			// reset the cartridge options
			setCartridgeOptions(
				cartridgeOptions.map((cartridgeOption) => {
					return { ...cartridgeOption, updatedHighlight: false };
				}),
			);

			setUpdateSuccess(true);

			setTimeout(() => {
				setUpdateSuccess(false);
			}, 5000);
		} catch (error) {
			console.error(error);
			setUpdateFail(true);

			setTimeout(() => {
				setUpdateFail(false);
			}, 5000);
		}
	};

	const changeEnabled = (index) => {
		setAlertSaveChanges(true);
		const tempIngredients = machineIngredients.slice();
		tempIngredients[index].enabled = !tempIngredients[index].enabled;
		setMachineIngredients(tempIngredients);
	};

	const onChangeDropDownIngredient = (e, { value }, cartridge, oldIngredient) => {
		setAlertSaveChanges(true);

		// update the machine ingredients with the new ingredient
		const newIngredient = all.find((ingredient) => {
			return ingredient.id === value;
		});

		// update the changeIngredientTo array with the new ingredient
		setChangeIngredientTo(
			changeIngredientTo.map((changeIngredient) => {
				if (changeIngredient.key === cartridge.value + 1) {
					// update array with the old and new ingredient and set changed to true
					return { ...changeIngredient, from: oldIngredient, to: newIngredient, changed: true };
				}
				return changeIngredient;
			}),
		);

		// update machine ingredients with the new ingredient
		setMachineIngredients(
			machineIngredients.map((ingredient) => {
				if (ingredient.name === oldIngredient.name) {
					// set avaible value to value of old ingredient and also the enabled value
					newIngredient.available = ingredient.available;
					newIngredient.enabled = ingredient.enabled;
					return newIngredient;
				}
				return ingredient;
			}),
		);

		// set available to true and compartment to the cartridge number + set the new ingredient name and old ingredient name
		newIngredient.compartment = cartridge.value;
		setNewIngredientName(newIngredient.name);
		setOldIngredientName(oldIngredient.name);

		// set updated highlight to true for the cartridge
		setCartridgeOptions(
			cartridgeOptions.map((cartridgeOption) => {
				if (cartridgeOption.value === cartridge.value) {
					return { ...cartridgeOption, updatedHighlight: true };
				}
				return cartridgeOption;
			}),
		);

		// remove new ingredient from the ingredient options and add the old ingredient back to the ingredient options
		setIngredientsOptions(
			ingredientsOptions.map((ingredientOption) => {
				if (ingredientOption.value === value) {
					return { key: oldIngredient.id, value: oldIngredient.id, text: oldIngredient.name };
				}
				return ingredientOption;
			}),
		);

		//remove new ingredient form the all ingredients list and add the old ingredient back to the all ingredients list
		setAll(
			all.map((ingredient) => {
				if (ingredient.id === value) {
					return oldIngredient;
				}
				return ingredient;
			}),
		);

		// show user by changing indredients which machine recipes will be affected
		updateAffectedRecipes(oldIngredient);
	};

	const updateAffectedRecipes = (oldIngredient) => {
		if (currentMachineRecipes !== undefined) {
			const affectedRecipes = currentMachineRecipes.filter((recipe) => {
				return recipe.smoothieParts.some((ingredient) => ingredient.flavor.name === oldIngredient.name);
			});

			console.log('affectedRecipes', affectedRecipes);
			setAffectedRecipesByChange(affectedRecipes);
		}
	};

	return (
		<Container>
			{affectedRecipesByChange.length > 0 && (
				<div className="ui warning message">
					<div className="header">
						Changing {oldIngredientName} to {newIngredientName} will affect the following recipes:
					</div>
					<ul className="list">
						{affectedRecipesByChange.map((recipe) => (
							<li key={recipe.id}>{recipe.name}</li>
						))}
					</ul>
				</div>
			)}

			{alertSaveChanges && (
				<Message warning>
					<Message.Header>Save Changes</Message.Header>
					<p>Don't forget to save your changes!</p>
				</Message>
			)}

			{updateSuccess && (
				<Message positive>
					<Message.Header>Success</Message.Header>
					<p>Ingredients updated successfully!</p>
				</Message>
			)}

			{updateFail && (
				<Message negative>
					<Message.Header>Failed</Message.Header>
					<p>Ingredients failed to update!</p>
				</Message>
			)}

			<Table celled compact definition>
				<TableHeader fullWidth>
					<TableRow>
						<TableHeaderCell>Cartridge</TableHeaderCell>
						<TableHeaderCell>Ingredients</TableHeaderCell>
						<TableHeaderCell>Current</TableHeaderCell>
						<TableHeaderCell>Available</TableHeaderCell>
						<TableHeaderCell>Enabled</TableHeaderCell>
					</TableRow>
				</TableHeader>

				<TableBody>
					{cartridgeOptions.map((cartridge, index) => (
						<TableRow key={index} positive={cartridge.updatedHighlight}>
							<TableCell>{cartridge.text}</TableCell>
							<TableCell>
								<Dropdown
									fluid
									search
									text={machineIngredients[index]?.name}
									header="Select an ingredient"
									selection
									options={ingredientsOptions}
									onChange={(e, { value }) =>
										onChangeDropDownIngredient(e, { value }, cartridge, machineIngredients[index])
									}
									onClick={IngredientsNotInMachine}
								/>
							</TableCell>
							<TableCell>
								{changeIngredientTo[index].changed && (
									<>
										<span style={{ marginRight: '0.5rem' }}>Change</span>
										<Label
											as="a"
											style={{
												border: '1px solid ' + changeIngredientTo[index].from.flavor.color,
												marginRight: '0.5rem',
											}}
										>
											<img
												style={{ maxWidth: '2rem', maxHeight: '2rem' }}
												src={changeIngredientTo[index].from.flavor.icon}
												alt={changeIngredientTo[index].from.flavor.name}
											/>
										</Label>
										{changeIngredientTo[index].from.flavor.name}

										<Icon name="arrow right" />
									</>
								)}
								<Label
									as="a"
									style={{ border: '1px solid ' + machineIngredients[index]?.flavor.color, marginRight: '0.5rem' }}
								>
									<img
										style={{ maxWidth: '2rem', maxHeight: '2rem' }}
										src={machineIngredients[index]?.flavor.icon}
										alt={machineIngredients[index] ? machineIngredients[index].flavor.name : ''}
									/>
								</Label>
								{machineIngredients[index]?.flavor.name}
							</TableCell>
							<TableCell
								positive={machineIngredients[index]?.available}
								negative={!machineIngredients[index]?.available}
							>
								<Icon name={machineIngredients[index]?.available ? 'checkmark' : 'close'} />
								{machineIngredients[index]?.available ? 'Yes' : 'No'}
							</TableCell>
							<TableCell positive={machineIngredients[index]?.enabled} negative={!machineIngredients[index]?.enabled}>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<Checkbox slider checked={machineIngredients[index]?.enabled} onChange={() => changeEnabled(index)} />
									<Icon name={machineIngredients[index]?.enabled ? 'checkmark' : 'close'} />
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>

				<TableFooter fullWidth>
					<TableRow>
						<TableHeaderCell colSpan="5">
							<Button floated="right" icon labelPosition="left" primary onClick={updateIngredients}>
								<Icon name="save" /> Save
							</Button>
						</TableHeaderCell>
					</TableRow>
				</TableFooter>
			</Table>
		</Container>
	);
};

export default MachineIngredient;
