import React from 'react';
import './MachineFilter.css';

const MachineFilter = ({
	totalMachines,
	amountOfMachinesOnline,
	amountOfMachinesOffline,
	amountOfMachineSmoothie,
	amountOfMachineSoup,
	amountOfMachineCombo,
	onFilterClick,
	clearStatusFilter,
	clearTypeFilter,
	statusFilter,
	typeFilter,
}) => {
	return (
		<section>
			<div className="machine-stats">
				<div className={`machine-stats-card ${statusFilter ? 'filter-active' : ''}`}>
					<div className="machine-stats-card-header">
						<h3>Status</h3>

						{statusFilter && (
							<div className="clear-filter-button" onClick={clearStatusFilter} aria-label="Cancel">
								<i className="pi pi-times"></i>
							</div>
						)}
					</div>

					{statusFilter !== 'offline' && (
						<div className="machine-stats-card-status" onClick={() => onFilterClick('status', 'online')}>
							<div className="machine-stats-card-status-flex">
								<p>Online</p>

								<p>{amountOfMachinesOnline}</p>
							</div>

							<progress className="progress-online" value={amountOfMachinesOnline} max={totalMachines} />
						</div>
					)}

					{statusFilter !== 'online' && (
						<div className="machine-stats-card-status" onClick={() => onFilterClick('status', 'offline')}>
							<div className="machine-stats-card-status-flex">
								<p>Offline</p>

								<p>{amountOfMachinesOffline}</p>
							</div>

							<progress className="progress-offline" value={amountOfMachinesOffline} max={totalMachines} />
						</div>
					)}
				</div>

				<div className={`machine-stats-card ${typeFilter ? 'filter-active' : ''}`}>
					<div className="machine-stats-card-header">
						<h3>Types</h3>

						{typeFilter && (
							<div className="clear-filter-button" onClick={clearTypeFilter} aria-label="Cancel">
								<i className="pi pi-times"></i>
							</div>
						)}
					</div>

					{typeFilter !== 'SOUP' && typeFilter !== 'COMBO' && (
						<div className="machine-stats-card-status" onClick={() => onFilterClick('type', 'SMOOTHIE')}>
							<div className="machine-stats-card-status-flex">
								<p>Smoothie</p>

								<p>{amountOfMachineSmoothie}</p>
							</div>

							<progress className="progress" value={amountOfMachineSmoothie} max={totalMachines} />
						</div>
					)}

					{typeFilter !== 'SMOOTHIE' && typeFilter !== 'COMBO' && (
						<div className="machine-stats-card-status" onClick={() => onFilterClick('type', 'SOUP')}>
							<div className="machine-stats-card-status-flex">
								<p>Soup</p>

								<p>{amountOfMachineSoup}</p>
							</div>

							<progress className="progress" value={amountOfMachineSoup} max={totalMachines} />
						</div>
					)}

					{typeFilter !== 'SMOOTHIE' && typeFilter !== 'SOUP' && (
						<div className="machine-stats-card-status" onClick={() => onFilterClick('type', 'COMBO')}>
							<div className="machine-stats-card-status-flex">
								<p>Combo</p>

								<p>{amountOfMachineCombo}</p>
							</div>

							<progress className="progress" value={amountOfMachineCombo} max={totalMachines} />
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default MachineFilter;
