import axios from 'axios';

function handleError(error) {
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		if (error.response.data) {
			//alert(error.response.data.error_description);
			throw new Error(error.response.data.error_description);
		} else {
			//alert(error.response);
			throw new Error('Something went wrong');
		}
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		throw new Error('No response received');
	} else {
		// Something happened in setting up the request that triggered an Error
		throw new Error(error.message);
	}
}

let baseURLCloud = process.env.REACT_APP_CLOUD_URL;

// if env variable is not set, use default url
if (baseURLCloud === undefined) {
	baseURLCloud = 'http://localhost:8080';
}

export default class ApiService {
	constructor(baseURL) {
		this.api = axios.create({
			baseURL,
			headers: {
				'Content-Type': 'application/json',
			},
		});
		this.apiCloud = axios.create({
			baseURL: baseURLCloud,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	getBaseUrl() {
		return baseURLCloud;
	}

	async get(url, config) {
		try {
			const result = await this.api.get(url, config);
			return result;
		} catch (error) {
			handleError(error);
		}
	}

	async post(url, data, config) {
		try {
			const result = await this.api.post(url, data, config);
			return result;
		} catch (error) {
			handleError(error);
		}
	}
	async put(url, data, config) {
		try {
			const result = await this.api.put(url, data, config);
			return result;
		} catch (error) {
			handleError(error);
		}
	}
	async delete(url, config) {
		try {
			const result = await this.api.put(url, config);
			return result;
		} catch (error) {
			handleError(error);
		}
	}
	async getCloud(url, config) {
		try {
			const result = await this.apiCloud.get(url, config);
			return result;
		} catch (error) {
			handleError(error);
		}
	}
	async postCloud(url, data, config) {
		try {
			const result = await this.apiCloud.post(url, data, config);
			return result;
		} catch (error) {
			handleError(error);
		}
	}

	async putCloud(url, data, config) {
		try {
			const result = await this.apiCloud.put(url, data, config);
			return result;
		} catch (error) {
			handleError(error);
		}
	}

	async deleteCloud(url, config) {
		try {
			const result = await this.apiCloud.delete(url, config);
			return result;
		} catch (error) {
			handleError(error);
		}
	}

	async sendToken(payload) {
		return this.get('oauth/token', payload);
	}

	async getToken() {
		return this.postCloud(
			'oauth/token',
			{},
			{
				headers: {
					'Authorization': 'Basic ' + btoa('mobileApp:Smail@Pegus'),
					'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
				},
			},
		);
	}

	async authorize() {
		return this.post('oauth/authorize?grant_type=authorization_code&response_type=code&client_id=mobileApp');
	}
	async login(payload) {
		return this.postCloud('api/users/google/login', { tokenId: payload });
	}

	async getMachines(headers) {
		return this.getCloud('api/machines/overview', { headers: headers });
	}

	async getMachine(id, headers) {
		return this.getCloud(`api/machines/${id}`, { headers: headers }); // before: ../details/...
	}

	async getMachinesForIngredient(ingredientId, available, headers) {
		return this.getCloud(`api/machines/machineidsforingredient/${ingredientId}?available=${available}`, {
			headers: headers,
		});
	}

	async getMachinesForRecipe(recipeId, available, headers) {
		return this.getCloud(`api/machines/machineidsforrecipe/${recipeId}?available=${available}`, {
			headers: headers,
		});
	}

	async getAllLocations(headers) {
		return this.getCloud('api/location', { headers: headers });
	}

	async getLocationById(id, headers) {
		return this.getCloud(`api/location/${id}`, { headers: headers });
	}

	async updateLocation(id, data, headers) {
		return this.putCloud(`api/location/${id}`, data, { headers: headers });
	}

	async addLocation(data, headers) {
		return this.postCloud(`api/location`, data, { headers: headers });
	}

	async updateMachineLocation(id, data, headers) {
		return this.putCloud(`/api/machines/${id}/location`, data, {
			headers: headers,
		});
	}

	async getFlavors(headers) {
		return this.getCloud('api/flavor', { headers: headers });
	}

	async createFlavors(data, headers) {
		return this.postCloud('api/flavor', data, { headers: headers });
	}

	async updateFlavor(id, data, headers) {
		return this.putCloud(`/api/flavor/${id}`, data, { headers: headers });
	}

	async startMaintenance() {
		return this.post('api/maintenance/start');
	}

	async stopMaintenance() {
		return this.post('api/maintenance/stop');
	}

	async openFreezer() {
		return this.post('api/maintenance/freezer/open');
	}

	async closeFreezer() {
		return this.post('api/maintenance/freezer/close');
	}

	async openCupLock() {
		return this.post('api/maintenance/cuplock/open');
	}

	async closeCupLock() {
		return this.post('api/maintenance/cuplock/close');
	}

	async closeCupLockSlow() {
		return this.post('api/maintenance/cuplock/close/slow');
	}

	async openCupHatch() {
		return this.post('api/maintenance/cuphatch/open');
	}

	async closeCupHatch() {
		return this.post('api/maintenance/cuphatch/close');
	}

	async cupTaken() {
		return this.post('api/maintenance/cup-taken');
	}

	async enableWater(waterEnabled) {
		if (waterEnabled) {
			return this.post('api/maintenance/disable/water');
		} else {
			return this.post('api/maintenance/enable/water');
		}
	}

	async enableBoiler(boilerEnabler) {
		if (boilerEnabler) {
			return this.post('api/maintenance/disable/boiler');
		} else {
			return this.post('api/maintenance/enable/boiler');
		}
	}

	async enableIngredients(ingredientsEnabled) {
		if (ingredientsEnabled) {
			return this.post('api/maintenance/disable/ingredients');
		} else {
			return this.post('api/maintenance/enable/ingredients');
		}
	}

	async enableBlender(blenderEnabled) {
		if (blenderEnabled) {
			return this.post('api/maintenance/disable/blender');
		} else {
			return this.post('api/maintenance/enable/blender');
		}
	}

	async start() {
		return this.post('api/machine/start', { performClean: false });
	}

	async startCleaning() {
		return this.post('api/maintenance/cleaning/start');
	}

	async reset() {
		return this.post('api/maintenance/reset');
	}

	async verticalBlocked(blocked) {
		return this.post('api/maintenance/vertical/block', blocked);
	}

	async freezerInCupLc(cupInLc) {
		return this.post('api/maintenance/freezer/cup-lc', cupInLc);
	}

	async blenderInCupLc(cupInLc) {
		return this.post('api/maintenance/blender/cup-lc', cupInLc);
	}

	async dispenseCup1() {
		return this.post('api/maintenance/dispense/cup1');
	}
	async dispenseCup2() {
		return this.post('api/maintenance/dispense/cup2');
	}

	async initBlender() {
		return this.post('api/maintenance/blender/init');
	}

	async blendCupFromDispenser() {
		return this.post('api/maintenance/blend/cup-from-dispenser');
	}

	async blendCupToCupLock() {
		return this.post('api/maintenance/blend/cup-to-cuplock');
	}

	async blendCupFromCupLock() {
		return this.post('api/maintenance/blend/cup-from-cuplock');
	}

	async blendCupInCupHatch() {
		return this.post('api/maintenance/blend/cup-in-cuphatch');
	}

	async blendWaitDropCupHatch() {
		return this.post('api/maintenance/blend/wait-drop-cuphatch');
	}

	async blendCupFreezerDown() {
		return this.post('api/maintenance/blend/cup-freezer-down');
	}

	async blendCupFreezerTop() {
		return this.post('api/maintenance/blend/cup-freezer-top');
	}

	async initFreezer() {
		return this.post('api/maintenance/freezer/init');
	}

	async freezerGuideUnderCartridge(cartridge) {
		return this.post('api/maintenance/freezer/guide-under-cartridge', cartridge);
	}

	async goToWaitPosition() {
		return this.post('api/maintenance/freezer/go-to-wait-pos');
	}

	async goToHandover() {
		return this.post('api/maintenance/freezer/go-to-handover');
	}

	async turnArm() {
		return this.post('api/maintenance/freezer/turn-arm');
	}

	async homeRailBlender() {
		return this.post('api/maintenance/blender/rail/home');
	}

	async blenderCleaningChamber() {
		return this.post('api/maintenance/blender/cleaning-chamber');
	}

	async blenderStartFillPosition() {
		return this.post('api/maintenance/blender/start-fill-position');
	}

	async blenderToCupLockPosition() {
		return this.post('api/maintenance/blender/cuplock-position');
	}

	async createSmoothie(waterRatio, ingredients) {
		return this.post('api/maintenance/smoothie', { waterRatio, ingredients });
	}

	async disableIngredientsList(ingredients) {
		return this.post('/disable/ingredients/list', { ingredients });
	}

	async getAllIngredients(headers) {
		return this.getCloud('/api/ingredient', { headers: headers });
	}

	async getRecipes(page = 0, headers) {
		return this.getCloud(`/api/recipes/all?page=${page}`, { headers: headers });
	}

	async getRecipesByCategory(category, page = 0, headers) {
		return this.getCloud(`/api/recipes/all?page=${page}&category=${category}`, {
			headers: headers,
		});
	}

	async getRecipesByName(pattern, page = 0, headers) {
		return this.getCloud(`/api/recipes/filter?pattern=${pattern}&page=${page}`, { headers: headers });
	}

	async getAllMachineRecipes(headers) {
		return this.getCloud('/api/recipes/all?page=0&size=100&category=MACHINE', {
			headers: headers,
		});
	}

	async uploadFile(data, headers) {
		return this.postCloud('/api/file/upload', data, { headers: headers });
	}

	async createIngredient(data, headers) {
		return this.postCloud('/api/ingredient', data, { headers: headers });
	}

	async updateIngredient(id, data, headers) {
		return this.putCloud(`/api/ingredient/${id}`, data, { headers: headers });
	}

	async createRecipe(data, headers) {
		return this.postCloud('/api/recipes', data, { headers: headers });
	}

	async updateRecipe(id, data, headers) {
		return this.putCloud(`/api/recipes/${id}`, data, { headers: headers });
	}

	async updateMachineIngredients(id, data, headers) {
		return this.putCloud(`/api/machines/${id}/ingredients`, data, {
			headers: headers,
		});
	}

	async getIngredient(id, headers) {
		return this.getCloud(`/api/ingredient/${id}`, { headers: headers });
	}

	async getStateIngredientsInMachine(id, headers) {
		return this.getCloud(`/api/machines/${id}/ingredients`, {
			headers: headers,
		});
	}

	async getMachineRecipes(id, headers) {
		return this.getCloud(`/api/machines/${id}/recipes`, { headers: headers });
	}

	async updateMachineRecipes(id, data, headers) {
		return this.putCloud(`/api/machines/${id}/recipes`, data, {
			headers: headers,
		});
	}

	async getPromotions(headers) {
		return this.getCloud(`/api/promotion`, { headers: headers });
	}

	async getCodes(headers) {
		return this.getCloud('/api/code', { headers: headers });
	}

	async getRevokedCodes(headers) {
		return this.getCloud('/api/code/revoked', { headers: headers });
	}

	async createCode(data, headers) {
		return this.postCloud('/api/code', data, { headers: headers });
	}

	async updateCode(codeId, data, headers) {
		return this.putCloud(`/api/code/${codeId}`, data, { headers: headers });
	}

	async revokeCode(codeId, headers) {
		return this.deleteCloud(`/api/code/${codeId}/revoke`, { headers: headers });
	}

	async createPromotion(data, headers) {
		return this.postCloud('/api/promotion', data, { headers: headers });
	}

	async updateMachine(id, data, headers) {
		return this.putCloud(`/api/machines/${id}`, data, { headers: headers });
	}

	async getMovie(movieId, headers) {
		return this.getCloud(`/api/movies/${movieId}/stream`, { headers: headers });
	}

	async getAllMovies(headers) {
		return this.getCloud(`/api/movies`, { headers: headers });
	}

	async updateMachineMovies(machineId, movies, headers) {
		return this.putCloud(`/api/machines/${machineId}/movies`, movies, {
			headers: headers,
		});
	}

	async addMovie(data, headers) {
		return this.postCloud(`/api/movies`, data, { headers: headers });
	}

	async addOperator(data, headers) {
		return this.postCloud(`/api/users/add-operator`, data, {
			headers: headers,
		});
	}

	async removeOperator(data, headers) {
		return this.postCloud(`/api/users/remove-operator`, data, {
			headers: headers,
		});
	}

	async createMachine(data, headers) {
		return this.postCloud(`/api/machines`, data, { headers: headers });
	}

	async getOperators(headers) {
		return this.getCloud(`/api/users/operators`, { headers: headers });
	}

	async getCurrentUser(headers) {
		return this.getCloud(`/api/users/me`, { headers: headers });
	}

	async listReferenceIntakes(headers) {
		return this.getCloud(`/api/ingredient/reference-intake`, {
			headers: headers,
		});
	}

	async createReferenceIntake(data, headers) {
		return this.postCloud(`/api/ingredient/reference-intake`, data, {
			headers: headers,
		});
	}

	async updateReferenceIntake(referenceIntakId, data, headers) {
		return this.putCloud(`/api/ingredient/reference-intake/${referenceIntakId}`, data, { headers: headers });
	}

	async getAllCupConfigurations(headers) {
		return this.getCloud(`/api/cup`, { headers: headers });
	}

	async getCupById(id, headers) {
		return this.getCloud(`/api/cup/${id}`, { headers: headers });
	}

	async getRecipeTypeSettings(headers) {
		return this.getCloud(`/api/recipes/recipeTypeSettings/all`, {
			headers: headers,
		});
	}

	async getRecipeCategories(headers) {
		return this.getCloud(`/api/recipes/categories`, { headers: headers });
	}
}
