import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { FilterMatchMode } from 'primereact/api';
import { Paginator } from 'primereact/paginator';

import ApiService from '../../services/ApiService';
import { useAuth } from '../../context/auth';

import './AllRecipes.css';

const api = new ApiService();

const useDebounce = (callback, delay) => {
	const timer = useRef(null);

	const debouncedFunction = useCallback(
		(...args) => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
			timer.current = setTimeout(() => {
				callback(...args);
			}, delay);
		},
		[callback, delay],
	);

	useEffect(() => {
		return () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
		};
	}, []);

	return debouncedFunction;
};

const AllRecipes = () => {
	const { authTokens } = useAuth();
	const history = useHistory();

	const [recipes, setRecipes] = useState([]);

	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);

	const [filterName, setFilterName] = useState('');

	const [loading, setLoading] = useState(true);

	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const headers = useMemo(
		() => ({
			'X-Firebase-Auth-Client': authTokens.token,
		}),
		[authTokens],
	);

	/**
	 *
	 * Same here, the categories are not used in the current implementation but it's here for future use
	 */
	// useEffect(() => {
	// 	const fetchCategories = async () => {
	// 		try {
	// 			const response = await api.getRecipeCategories(headers);
	// 			setCategories(response.data.map((category) => ({ name: category, value: category }))); // Ensure correct format
	// 		} catch (error) {
	// 			console.error(error);
	// 		}
	// 	};

	// 	fetchCategories();

	// 	return () => {
	// 		setCategories([]);
	// 	};
	// }, [headers]);

	const fetchRecipes = async (name = '', page = 0) => {
		setLoading(true);
		try {
			let response;
			if (name) {
				response = await api.getRecipesByName(name, page, headers);
			} else {
				response = await api.getRecipes(page, headers);
			}
			setRecipes(response.data.recipes);
			setTotalPages(response.data.totalPages);
			console.log('response', response);
		} catch (error) {
			console.error('Error fetching recipes:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchRecipes();

		return () => {
			setRecipes([]);
		};
	}, [headers]);

	const debouncedFetchRecipes = useDebounce((name, page) => {
		fetchRecipes(name, page);
	}, 300);

	const handleNameFilterChange = (e) => {
		setFilterName(e.target.value);
		setCurrentPage(0);
		debouncedFetchRecipes(e.target.value, 0);
	};

	const clearNameFilter = () => {
		setFilterName('');
		setCurrentPage(0);
		fetchRecipes('', 0);
	};
	const getSeverity = (type) => {
		switch (type) {
			case 'ORIGINAL':
				return 'primary';
			case 'SMOOTHIE':
				return 'info';
			case 'SOUP':
				return 'info';
			case 'FRAPE':
				return 'info';
			case 'MOBILE':
				return 'success';
			case 'MACHINE':
				return 'success';
			case 'TEST':
				return 'warning';
			default:
		}
	};

	const categoriesTemplate = (rowData) => {
		const categories = rowData.categories;

		return (
			<div>
				{categories.map((type) => (
					<Tag
						key={type}
						value={type.toLowerCase()}
						className="recipes-template-categories"
						severity={getSeverity(type)}
					/>
				))}
			</div>
		);
	};

	const actionTemplate = (rowData) => {
		return (
			<Button icon="pi pi-pencil" text rounded size="small" onClick={() => history.push('/recipe/update', rowData)} />
		);
	};

	const recipeTemplate = (rowData) => {
		return (
			<div className="flex align-items-center w-full">
				{rowData.image && (
					<div className="recipe-template-container">
						<img src={rowData.image} alt={rowData.name} className="recipe-template-image" />
					</div>
				)}

				<div className="recipe-template-content-container">
					<p className="recipe-template-name">{rowData.name}</p>

					<p className="recipe-template-description-container-description">{rowData.description}</p>
				</div>
			</div>
		);
	};

	const labelTemplate = (rowData) => {
		return (
			<div className="recipe-template-label-container">
				{rowData.badgeIconUrl ? <img src={rowData.badgeIconUrl} alt="Label" /> : <span>--</span>}
			</div>
		);
	};

	const ingredientsTemplate = (rowData) => {
		return (
			<div className="flex align-items-center gap-1">
				{rowData.smoothieParts.map((ingredient) => (
					<img
						key={ingredient.id}
						src={ingredient.flavor.icon}
						alt={ingredient.flavor.name}
						className="recipe-ingredient-template-image"
					/>
				))}
			</div>
		);
	};

	const onPageChange = (event) => {
		setCurrentPage(event.page);
		debouncedFetchRecipes(filterName, event.page);
	};

	const paginatorTemplate = {
		layout: 'CurrentPageReport PrevPageLink NextPageLink',

		CurrentPageReport: (options) => {
			return (
				<span className="current-page-report-template">
					{options.first} - {options.last} of {options.totalRecords}
				</span>
			);
		},
	};

	const header = (
		<div className="flex align-items-center justify-content-between">
			<div className="flex align-items-center gap-1">
				<InputText
					value={filterName}
					onChange={handleNameFilterChange}
					placeholder="Filter by name"
					className="p-inputtext-sm mr-1"
					type="text"
				/>

				{filterName && (
					<Button
						icon="pi pi-filter-slash"
						text
						rounded
						size="small"
						onClick={() => clearNameFilter()}
						className="clear-filter-button"
					/>
				)}
			</div>

			<Paginator
				first={currentPage * 20}
				rows={20}
				totalRecords={totalPages * 20}
				onPageChange={onPageChange}
				template={paginatorTemplate}
			/>
		</div>
	);

	return (
		<div className="mt-5 main-datatable all-recipe-datatable">
			<Button
				label="Create new Recipe"
				icon="pi pi-plus"
				text
				size="small"
				onClick={() => history.push('/recipe/create')}
				className="m-2"
			/>

			<DataTable
				sortField="name"
				sortOrder={1}
				value={recipes}
				header={header}
				dataKey="id"
				removableSort
				emptyMessage="No recipes found"
				filters={filters}
				loading={loading}
				showGridlines
				size="small"
				onFilter={(e) => setFilters(e.filters)}
			>
				<Column field="name" header="Recipe" sortable body={recipeTemplate} style={{ width: '15rem' }} />
				<Column field="badgeIconUrl" header="Label" body={labelTemplate} style={{ width: '5rem' }} />
				<Column header="Categories" field="categories" body={categoriesTemplate} style={{ minWidth: '8rem' }} />
				<Column field="smoothieParts" header="Ingredients" body={ingredientsTemplate} style={{ width: '15rem' }} />
				<Column header="Actions" body={actionTemplate} style={{ width: '5rem' }} />
			</DataTable>
		</div>
	);
};

export default AllRecipes;
